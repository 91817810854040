<template>
  <Popup :open="open" @close="$emit('close', $event)" @update="$emit('close')">
    <div class="popup__header">
      <h2 class="popup__title">Filters</h2>
      <CommonButton @clicked="clearAll" :caption="'Clear All'" variant="text" />
    </div>

    <h3 class='popup__subtitle'>Choose challenges in your feed</h3>
    <div class='popup__button-group'>
      <FilterButton
        v-for="(item, index) in challenges.list"
        @clicked="toggleChallenge(item.key)"
        :key="index"
        :caption="item.text"
        :is-active="selectedChallenges.includes(item.key)"
      />
    </div>

    <h3 class='popup__subtitle'>Choose your discipline</h3>
    <div class='popup__button-group'>
      <FilterButton
        v-for="(sport, index) in sportView.list"
        @clicked="toggleSport(sport.key)"
        :key="index"
        :caption="sport.text"
        :is-active="selectedDisciplines.includes(sport.key)"
      />
    </div>

    <footer class='popup__footer'>
      <CommonButton
        :caption="'Apply'"
        @clicked="$emit('update', selectedChallenges, selectedDisciplines)"
      />
    </footer>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import FilterButton from '@/components/UI/FilterButton'
import CommonButton from '@/components/UI/CommonButton'
import { mapState } from 'vuex'

export default {
  name: 'FilterPopup',
  components: {
    Popup, FilterButton, CommonButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedChallenges: [],
      selectedDisciplines: [],
    }
  },
  computed: {
    ...mapState('home', ['sportView', 'challenges']),
  },
  watch: {
    open() {
      this.selectedChallenges = [...this.challenges.selectedList];
      this.selectedDisciplines = [...this.sportView.selectedList];
    }
  },
  methods: {
    toggleSport(sportKey) {
      if (this.selectedDisciplines.includes(sportKey)) {
        this.selectedDisciplines = this.selectedDisciplines.filter(e => e !== sportKey)
      }
      else {
        this.selectedDisciplines.push(sportKey);
      }
    },
    toggleChallenge(key) {
      if (this.selectedChallenges.includes(key)) {
        this.selectedChallenges = this.selectedChallenges.filter(e => e !== key)
      }
      else {
        this.selectedChallenges.push(key);
      }
    },
    clearAll() {
      this.selectedChallenges.splice(0, this.selectedChallenges.length);
      this.selectedDisciplines.splice(0, this.selectedDisciplines.length);
    }
  }
}
</script>

<style lang="stylus" scoped>
  .popup
    font-family Manrope, sans-serif

    &__header
      display flex
      justify-content space-between
      align-items baseline
      gap 20px
      margin-bottom 20px

    &__title
      color #EEEEEE
      font-size 20px
      line-height 1.4
      font-weight 600
      margin-bottom 0

    &__subtitle
      margin-bottom 12px
      line-height 1.25
      color #7B8B97
      font-size 16px
      font-weight 400

    &__button-group
      display flex
      gap 8px
      flex-wrap wrap
      margin-bottom 32px

    &__button-inline
      color: #6293FF
      font-size 14px
      line-height 1.43
      font-weight 400
      cursor pointer

    &__footer
      margin 0 -16px -8px
      padding 16px
      background-color #141723

</style>
