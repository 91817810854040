<template>
  <view-container name="home">
    <view-header :fixed="true">

      <div class='header'>
        <div class="header__title">Feed</div>
        <button @click="toggleFilterPopup" class='header__filter-button'>
          <Icon :type="'filter'" :size="20" />
        </button>
      </div>
    </view-header>

    <view-content>
      <div class="content">
        <div v-if="sortSelectedItems.length === 0" class="empty">
          <p class="empty__text">We’re sorry, there is no result found for </p>
          <ul class="empty__list">
            <li class="empty__list-item" v-for="item in $store.state.home.sportView.selectedList" :key="item">
              {{ item }}
            </li>
            <li class="empty__list-item" v-for="item in $store.state.home.challenges.selectedList" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>

        <component v-for="item in sortSelectedItems" :is="itemComponent(item.entity)" :key="`${item.entity}_${item.uid}`"
          :tournament="item" :competition="item" />

        <!-- <Ad/> -->
      </div>

      <FilterPopup :open='isShowFilterPopup' @close='toggleFilterPopup' @update='updateFromFilter' />
    </view-content>

    <view-footer>
      <footer-competitions />
    </view-footer>
  </view-container>
</template>

<script>
import Ad from '@/components/Ad'
import Verdict from '@/components/Competitions/Verdict'
import SportSelect from '@/components/SportSelect'
import Competition from '@/components/Competitions/Competition'
import Tournament from '@/components/Competitions/Tournament'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Icon from '../components/Icon'
import FilterPopup from '@/components/Popups/FilterPopup'

export default {
  name: 'Home',
  components: {
    FilterPopup,
    Ad,
    Verdict,
    SportSelect,
    Competition,
    Tournament,
    Icon
  },
  data() {
    return {
      itWasViewedVerdictsTab: false,
      tabKey: 'opened',
      isShowFilterPopup: false,
    }
  },
  mounted() {
    // this.$store.dispatch('home/getCompetitions')
    // this.$store.dispatch('home/getTournaments')
    // this.$store.dispatch('home/getEvents')
    // this.$store.dispatch('home/getVerdicts')
    // this.$store.dispatch('home/getTournamentsVerdicts')
    // this.$store.dispatch('home/getEventsVerdicts')
    this.$store.dispatch('home/init')
    if (sessionStorage.getItem('itWasViewedVerdictsTab') !== null) {
      this.itWasViewedVerdictsTab = true
    }
    if (this.$route.hash === '#live') {
      this.$refs.tabLive.click()
    }
  },
  computed: {
    sortSelectedItems() {
      let data = [];
      const allSportsSelected = this.sportView.length === 0 ? true : false;
      const allChallengesSelected = this.challenges.length === 0 ? true : false;

      if (this.sportView.includes('swimming') || allSportsSelected) {
        if (this.challenges.includes('upcoming') || allChallengesSelected) {
          data = [...data, ...this.tournaments, ...this.allSwimmingCompetitions]
        }
        if (this.challenges.includes('streamed') || allChallengesSelected) {
          data = [...data, ...this.allSwimmingVerdicts.map(e => {
            if (!e.entity) {
              e.entity = 'verdict';
            }
            return e;
          })];
        }
      }

      if (this.sportView.includes('basketball') || allSportsSelected) {
        if (this.challenges.includes('upcoming') || allChallengesSelected) {
          data = [...data, ...this.basketballCompetitions]
        }

        if (this.challenges.includes('streamed') || allChallengesSelected) {
          data = [...data, ...this.basketballVerdicts.map(e => { e.entity = 'verdict'; return e; })];
        }
      }

      const sorted = data.sort((a, b) => {
        return new Date(b.start_at || b.event_at) - new Date(a.start_at || a.event_at);
      })

      const unique = [];
      for (const item of sorted) {
        const isDuplicate = unique.find((obj) => obj.id === item.id);
        if (!isDuplicate) {
          unique.push(item);
        }
      }
      return unique
    },
    ...mapState('home', {
      verdicts: ({ verdicts }) => verdicts,
      competitions: ({ competitions }) => competitions,
      tournaments: ({ tournaments }) => tournaments,
      events: ({ events }) => events,
      tournamentVerdicts: ({ tournamentVerdicts }) => tournamentVerdicts,
      eventsVerdicts: ({ eventsVerdicts }) => eventsVerdicts,
      challenges: ({ challenges }) => challenges.selectedList,
      sportView: ({ sportView }) => sportView.selectedList,
    }),
    ...mapGetters({
      existUnseenVerdicts: 'home/existUnseenVerdicts',
      isLogin: 'isLogin',
      selectedSport: 'home/selectedSport'
    }),
    live() {
      return this.verdicts.length
    },
    isShowLiveBadge() {
      if (this.isLogin) {
        return this.existUnseenVerdicts
      } else {
        return !this.itWasViewedVerdictsTab
      }
    },
    activeTabName() {
      switch (this.tabKey) {
        case 'opened':
          return this.$t('pages.home.opened');
        case 'live':
          return this.$t('pages.home.live');
        default:
          return ''
      }
    },
    swimmingCompetitions() {
      // TODO - need to display my events at home??
      const myCompet = this.$store.state.competitions.competitions;
      return [...this.competitions, ...myCompet].filter(el => (el.kind_sport?.name === "Swimming"));
    },
    swimmingVerdicts() {
      return this.verdicts.filter(el => (el.kind_sport.name === "Swimming"));
    },
    allSwimmingVerdicts() {
      let filteredVerdicts = this.tournamentVerdicts
      if (this.challenges.includes('streamed')) {
        filteredVerdicts = filteredVerdicts.filter(el => el.all_has_video_is_live === true)
      }

      return [...filteredVerdicts, ...this.eventsVerdicts, ...this.swimmingVerdicts]
    },
    basketballCompetitions() {
      // TODO - need to display my events at home??
      const myCompet = this.$store.state.competitions.competitions;
      return [...this.competitions, ...myCompet].filter(el => (el.kind_sport.name === "Basketball"));
    },
    basketballVerdicts() {
      return this.verdicts.filter(el => (el.kind_sport.name === "Basketball"));
    },
    allSwimmingCompetitions() {
      return [...this.events, ...this.swimmingCompetitions]
    }
  },
  methods: {
    ...mapMutations({
      setSports: 'home/SET_SELECTED_SPORTS',
      setChallenges: 'home/SET_SELECTED_CHALLENGES'
    }),
    itemComponent(type) {
      let component = 'competition'
      switch (type) {
        // case 'event':
        //   component = 'event'
        //   break;
        case 'tournament':
          component = 'tournament'
          break;
        case 'verdict':
          component = 'verdict'
          break;
        default:
      }
      return component
    },

    toggleFilterPopup() {
      this.isShowFilterPopup = !this.isShowFilterPopup;
    },

    updateFromFilter(challenges, disciplines) {
      this.isShowFilterPopup = false;
      // update
      this.setChallenges(challenges);
      this.setSports(disciplines);
    }
  },
}
</script>

<style lang="stylus" scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 16px 16px;
    background: #141723;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 16px 16px;
    overflow: visible;
    z-index: 100;

    &__title {
      font-family: Manrope, sans-serif;
      font-weight: 600;
      font-size: 28px;
      line-height: 1;
      color: #EEEEEE;
    }

    &__filter-button {
      background-color: #6293FF;
      flex: 36px 0 0;
      height: 36px;
      padding: 8px;
      border-radius: 8px;
    }
  }

  .view-content {
    padding: 84px 16px 16px;
  }

  .empty {
    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7B8B97;
      margin-bottom 16px
      margin-top 8px
    }

    &__list {
      display: flex;
      gap: 8px;
    }

    &__list-item {
      padding: 4px 7px;
      background: #2F354D;
      border-radius: 5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #7B8B97;
    }
  }
  .competition__share {
    padding: 30px 0;
    color: #D8D8D8;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    position: relative;
  }
  
  .competition__share>button {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .competition__share>button>span {
    margin-left: 17px;
  }
  
  .competition__copied {
    position: absolute;
    bottom: calc(100% - 25px);
  }
</style>
