<template>
  <button
    @click="clicked"
    :class="{ disabled: isDisabled, active: isActive }"
    :disabled="isDisabled"
    class="button"
  >{{ caption }}</button>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked() {
      this.$emit('clicked');
    }
  }
}
</script>

<style lang="stylus" scoped>
  .button {
    height: 36px;
    padding: 0 12px;
    line-height: 36px;
    background-color: #262B3F;
    color: #EEEEEE;
    border-radius: 8px;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;

    &.active {
      background-color: #6293FF;
    }

    &.disabled {
      color: #7B8B97;
    }
  }
</style>
